import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  NOT_AUTHORIZED_MESSAGE,
  AUTHORING_SERVICE_URL,
} from "../shared/constants";
import { onError } from "@apollo/client/link/error";

import { ROUTES } from "../shared/route-constants";
import { SERVICE_ROUTES } from "../shared/service-routes";
import { aegisClient } from "./aegis-config";
import { AccessToken } from "@okta/okta-auth-js";
const { ERROR } = ROUTES;
const { COPY_WRITING } = SERVICE_ROUTES;

const authLink = setContext(async (_, { headers }) => {
  const jwtToken = (await aegisClient.getAccessToken()) as AccessToken;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${jwtToken?.accessToken}`,
    },
  };
});

const onErrorLink = onError(({ networkError }: any) => {
  if (networkError) {
    const { statusCode } = networkError;
    if (statusCode === 401) {
      aegisClient.getIdToken();
      aegisClient.getAccessToken();
    } else if (statusCode === 403) {
      if (sessionStorage) {
        sessionStorage.setItem("errorMessage", NOT_AUTHORIZED_MESSAGE);
        window.history.pushState({}, "Error", `/${ERROR}`);
        window.location.reload();
      }
    }
    window.location.reload();
  }
});

const copyWritingServiceDatalink = createHttpLink({
  uri: AUTHORING_SERVICE_URL + COPY_WRITING,
});

export const copyAuthoringClient = new ApolloClient({
  link: authLink.concat(copyWritingServiceDatalink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export const localizationClient = new ApolloClient({
  link: authLink.concat(copyWritingServiceDatalink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
