export const ENVIRONMENT = process.env.NODE_ENV;
const env = ENVIRONMENT === "prod" ? "prod" : "nonProd";

export const RolesConfig = {
  nonProd: {
    authoringRead: "App.Lexi.NonProd.Authoring.Read",
    authoringWrite: "App.Lexi.NonProd.Authoring.Write",
    authoringAdmin: "App.Lexi.NonProd.Authoring.Admin",
    localizationRead: "App.Lexi.NonProd.Localization.Read",
    localizationWrite: "App.Lexi.NonProd.Localization.Write",
    localizationAdmin: "App.Lexi.NonProd.Localization.Admin",
    productAttributes: "Application.LEXI.Users",
    productAttributesRead: "App.Lexi.NonProd.ProductAttributes.Read",
    productAttributesWrite: "App.Lexi.NonProd.ProductAttributes.Write",
    productAttributesAdmin: "App.Lexi.NonProd.ProductAttributes.Admin",
    extLocalizationRead: "EXT.App.Lexi.Localization.Read",
    extLocalizationWrite: "EXT.App.Lexi.Localization.Write",
    extLocalizationAdmin: "EXT.App.Lexi.Localization.Admin",
    extAuthoringRead: "EXT.App.Lexi.Authoring.Read",
    extAuthoringWrite: "EXT.App.Lexi.Authoring.Write",
    extAuthoringAdmin: "EXT.App.Lexi.Authoring.Admin",
    extProductAttributes: "EXT.App.Lexi.Users",
    extProductAttributesRead: "EXT.App.Lexi.ProductAttributes.Read",
    extProductAttributesWrite: "EXT.App.Lexi.ProductAttributes.Write",
    extProductAttributesAdmin: "EXT.App.Lexi.ProductAttributes.Admin",
  },
  prod: {
    authoringRead: "App.Lexi.Authoring.Read",
    authoringWrite: "App.Lexi.Authoring.Write",
    authoringAdmin: "App.Lexi.Authoring.Admin",
    extAuthoringRead: "EXT.App.Lexi.Authoring.Read",
    extAuthoringWrite: "EXT.App.Lexi.Authoring.Write",
    extAuthoringAdmin: "EXT.App.Lexi.Authoring.Admin",
    localizationRead: "App.Lexi.Localization.Read",
    localizationWrite: "App.Lexi.Localization.Write",
    localizationAdmin: "App.Lexi.Localization.Admin",
    extLocalizationRead: "EXT.App.Lexi.Localization.Read",
    extLocalizationWrite: "EXT.App.Lexi.Localization.Write",
    extLocalizationAdmin: "EXT.App.Lexi.Localization.Admin",
    productAttributes: "Application.LEXI.Users",
    productAttributesRead: "App.Lexi.ProductAttributes.Read",
    productAttributesAdmin: "App.Lexi.ProductAttributes.Admin",
    productAttributesWrite: "App.Lexi.ProductAttributes.Write",
    extProductAttributes: "EXT.App.Lexi.Users",
    extProductAttributesRead: "EXT.App.Lexi.ProductAttributes.Read",
    extProductAttributesWrite: "EXT.App.Lexi.ProductAttributes.Write",
    extProductAttributesAdmin: "EXT.App.Lexi.ProductAttributes.Admin",
  },
};

const AuthoringRead = RolesConfig[env].authoringRead;
const AuthoringWrite = RolesConfig[env].authoringWrite;
const AuthoringAdmin = RolesConfig[env].authoringAdmin;

const LocalizationRead = RolesConfig[env].localizationRead;
const LocalizationWrite = RolesConfig[env].localizationWrite;
const LocalizationAdmin = RolesConfig[env].localizationAdmin;

const ProductAttributes = RolesConfig[env].productAttributes;
const ProductAttributesRead = RolesConfig[env].productAttributesRead;
const ProductAttributesAdmin = RolesConfig[env].productAttributesAdmin;

const extAuthoringAdmin = RolesConfig[env].extAuthoringAdmin;
const extAuthoringRead = RolesConfig[env].extAuthoringRead;
const extAuthoringWrite = RolesConfig[env].extAuthoringWrite;

const extLocalizationAdmin = RolesConfig[env].extLocalizationAdmin;
const extLocalizationRead = RolesConfig[env].extLocalizationRead;
const extLocalizationWrite = RolesConfig[env].extLocalizationWrite;

const extProductAttributes = RolesConfig[env].extProductAttributes;
const extProductAttributesAdmin = RolesConfig[env].extProductAttributesAdmin;
const extProductAttributesRead = RolesConfig[env].extProductAttributesRead;
const extProductAttributesWrite = RolesConfig[env].extProductAttributesWrite;

export const Roles = {
  AuthoringRead,
  AuthoringWrite,
  AuthoringAdmin,
  LocalizationRead,
  LocalizationWrite,
  LocalizationAdmin,
  ProductAttributes,
  ProductAttributesRead,
  ProductAttributesAdmin,
  extAuthoringAdmin,
  extAuthoringRead,
  extAuthoringWrite,
  extLocalizationAdmin,
  extLocalizationRead,
  extLocalizationWrite,
  extProductAttributes,
  extProductAttributesAdmin,
  extProductAttributesRead,
  extProductAttributesWrite,
};

export const AllowedRolesAuthoring = [
  AuthoringRead,
  AuthoringWrite,
  AuthoringAdmin,
  extAuthoringAdmin,
  extAuthoringRead,
  extAuthoringWrite,
];

export const AllowedRolesLocalization = [
  LocalizationRead,
  LocalizationWrite,
  LocalizationAdmin,
  extLocalizationAdmin,
  extLocalizationRead,
  extLocalizationWrite,
];

export const AllowedRolesProductAttributes = [
  ProductAttributes,
  ProductAttributesRead,
  ProductAttributesAdmin,
  extProductAttributes,
  extProductAttributesAdmin,
  extProductAttributesRead,
  extProductAttributesWrite,
];
