import gql from "graphql-tag";

export const getProductCopy = gql`
  query CopyBlocksByStyleId($styleId: String, $styleColor: String) {
    productCopy: productCopyByStyleId(
      styleFilterData: { styleId: $styleId, styleColor: $styleColor }
    ) {
      productId
      styleId
      styleColor
      model
      sourceLanguage
      productVendorId
      season
      voice
      productVendorName
      productDivision
      state
      translationStatus
      dueDate
      lastUpdateTs
      targetLanguages {
        language
        description
        dueDate
        translationStatus
        jobId
      }
      copyTypes {
        copyType
        copyText {
          headerId
          headerText
          bodyList {
            bodyId
            bodyText
          }
        }
      }
    }
  }
`;

export const getCopyblocksWithProductName = gql`
  query GetCopyblocksWithProductName(
    $pageNumber: Int!
    $pageSize: Int!
    $modelsStylesAndColors: [String!]
    $targetLanguages: [String]
    $style: Style
    $translationStatus: [String]
  ) {
    copyBlocks: products(
      paginationData: { pageNumber: $pageNumber, pageSize: $pageSize }
      filters: {
        filterByModelsStylesAndColors: $modelsStylesAndColors
        filterByStyle: $style
        filterByTargetLanguages: $targetLanguages
        translationStatus: $translationStatus
      }
    ) {
      pagination: paginationResponse {
        pageNumber
        totalItems
        totalPages
      }
      copyBlocks: copyBlocksWithProductName {
        productName
        copyBlock {
          id
          styleId
          styleColor
          model
          copyType
          sourceLanguage
          productVendorId
          season
          voice
          copyTextHeader
          copyTextBody
          productVendorName
          positionId
          productDivision
          state
          translationStatus
          dueDate
          creationUserId
          creationTs
          lastModificationUserId
          lastUpdateTs
          productLastUpdateTs
          localizedEdited
          localizedProductEdited
        }
      }
    }
  }
`;

export const getJobIDByStyleOrStyleColor = gql`
  query GetJobIDByStyleOrStyleColor($styleId: String, $styleColor: String) {
    jobIdInformation(
      styleFilterData: { styleId: $styleId, styleColor: $styleColor }
    ) {
      objects {
        jobId
        details {
          language
          dueDate
          translationStatus
          descLanguaje
        }
      }
    }
  }
`;

export const getTranslatedProductCopies = gql`
  query GetTranslatedProductCopies($styleId: String, $styleColor: String) {
    translatedProductName(
      translatedCopyBlockSearchFilter: {
        styleId: $styleId
        styleColor: $styleColor
      }
    ) {
      objects {
        name
        styleId
        styleColor
        sourceCopyReceived
        targetLanguage
        descTargetLanguage
        dueDate
        translationStatus
        edited
      }
    }
  }
`;

export const getJobIdDetails = gql`
  query GetJobIdDetails(
    $jobId: String
    $targetLanguage: String
    $marketPlace: String
  ) {
    jobIdInformationDetails(
      jobIdInformationFilter: {
        jobId: $jobId
        targetLanguage: $targetLanguage
        marketplace: $marketPlace
      }
    ) {
      objects {
        id
        jobId
        targetLanguage
        descTargetLanguage
        translationStatus
      }
    }
  }
`;
export const getCopyBlocksByLanguage = gql`
  query copyBlocksByLanguage(
    $targetLanguage: String!
    $styleId: String!
    $styleColor: String!
  ) {
    translatedCopyBlocksByLanguage(
      filter: {
        styleId: $styleId
        styleColor: $styleColor
        targetLanguage: $targetLanguage
      }
    ) {
      productId
      copyTypes {
        copyType
        copyText {
          headerId
          headerText
          locked
          positionId
          sourceCopyBlockId
          bodyList {
            bodyId
            bodyText
            locked
            positionId
            sourceCopyBlockId
          }
        }
      }
    }
  }
`;

export const getAllAtlasLanguages = gql`
  query getAllLanguages {
    allLanguages: getAllLanguages {
      objects {
        id
        name
      }
    }
  }
`;

export const SAVE_COPY_BLOCKS = gql`
  mutation SaveCopyBlocks($translatedCopyBlocks: [TranslatedCopyBlockInput]) {
    localizedCopyUpdate(translatedCopyBlocks: $translatedCopyBlocks) {
      jobId
      targetLanguage
    }
  }
`;

export const GET_BOM_DATA = gql`
  query FetchBom($productId: String!, $cycleYear: String!, $fetchAll: Boolean) {
    fetchAttributes(
      productId: $productId
      cycleYear: $cycleYear
      fetchAll: $fetchAll
    ) {
      styleNumber
      productLineStatus
      bomStatus
      cycleYear
      division
      styleName
      factory
      colorwayCode
      apparel {
        materials {
          partName
          variant
          quantity
          infos {
            materialDescription
            factory
          }
        }
        hangTags {
          partName
          materialDescription
          factory
        }
        fabricCare {
          partName
          type
          variant
          factory
          content
        }
        sizeFit {
          fit
          fitPreference
          sizeRange
        }
      }

      footwear {
        materials {
          infos {
            colorDescription
            materialClassification
            materialComment
            materialContent
            materialDescription
            materialName
            partComment
            colorWayCode
          }
          partName
          variant
        }
        airbags {
          materialClassification
          materialDescription
          colorWayCode
          partName
          partNumber
          technology
        }
        sizeFit {
          fit
          fitPreference
          sizeRange
        }
      }

      equipment {
        metrics {
          catalogMetric
          volume
          materials {
            partName
            materialDescription
          }
        }
      }
    }
  }
`;
export const GET_DIVISION = gql`
  query FetchDivision(
    $productId: String!
    $cycleYear: String!
    $fetchAll: Boolean
  ) {
    fetchAttributes(
      productId: $productId
      cycleYear: $cycleYear
      fetchAll: $fetchAll
    ) {
      division
      styleName
    }
  }
`;

export const GET_SEASONS = gql`
  query FetchSeason($productId: String!) {
    fetchSeason(productId: $productId) {
      styleNumber
      styleName
      division
      cycleYear
    }
  }
`;

export const RETRIGGER_TRANSLATIONS = gql`
  query RetriggerTranslations(
    $productCodes: [String]!
    $targetLanguages: [String]!
    $reason: String!
  ) {
    retriggerTranslations(
      retriggerTranslationsRequest: {
        productCodes: $productCodes
        targetLanguages: $targetLanguages
        reason: $reason
      }
    ) {
      productCodes
    }
  }
`;

export const GET_TARGET_LANGUAGES = gql`
  query getAllLanguagesForStyleIdAndStyleColor(
    $styleIdOrStyleColorId: [String!]
  ) {
    getAllLanguagesForStyleIdAndStyleColor(
      styleIdAndStyleColor: $styleIdOrStyleColorId
    ) {
      id
      name
    }
  }
`;

export const GET_PRODUCT_ATTRIBUTES = gql`
  query FetchProductAttributes($productId: String!, $cycleYear: String) {
    fetchSummary(productId: $productId, cycleYear: $cycleYear) {
      styleNumber
      styleName
      colourWays
      vendor
      division
      altStyleNumber
      pml
      developmentTeamName
      dimension
      segment
      globalConsumerOffense
      initialSeason
      bestSeason
      seasonRange
      inactiveSeasons
    }
  }
`;

export const GET_FEATURE_FLAGS = gql`
  query FetchFeatureFlags {
    featureFlags {
      id
      enabled
    }
  }
`;

export const GET_HEROIMAGE = gql`
  query FetchHeroImage($style: String!) {
    image(style: $style) {
      url
    }
  }
`;

export const GET_ALL_IMAGES = gql`
  query FetchAllImagesByStyle($style: String!) {
    image(style: $style) {
      id
      url
      defaultImage
      productRecordId
      styleNumber
      colorCode
      children {
        id
        url
        defaultImage
        productRecordId
        styleNumber
        colorCode
        children {
          id
          url
          defaultImage
          productRecordId
          styleNumber
          colorCode
        }
      }
    }
  }
`;

export const UPDATE_HERO_IMAGE = gql`
  mutation UpdateHeroImage($style: String!, $imageId: String!) {
    setHeroImage(style: $style, imageId: $imageId) {
      id
      url
      defaultImage
      productRecordId
      styleNumber
      colorCode
      children {
        id
        url
        defaultImage
        productRecordId
        styleNumber
        colorCode
        children {
          id
          url
          defaultImage
          productRecordId
          styleNumber
          colorCode
          children {
            id
            url
            defaultImage
            productRecordId
            styleNumber
            colorCode
          }
        }
      }
    }
  }
`;

export const GET_COPYJOB_DETAILS = gql`
  query FetchCopyjobDetails($jobId: String!) {
    fetchJobDetails(jobId: $jobId) {
      bestSeason
      jobId
      copyType
      copyTypeDesc
      displayName {
        line1
        line2
      }
      jobAttributes {
        jobType
        status
        reOpened
      }
      collectionId
      lastUpdatedDt
      lastUpdatedBy
      lastPublishedDt
      lastPublishedBy
      productCode
      aiInfo {
        aiGenerated
        confidenceScore
      }
      copyBlocks {
        blockId
        blockName
        blockDesc
        order
        required
        position
        aiBlockInfo {
          aiRequestId
          aiGenerated
          isAccepted
          isEdited
          confidenceScore
          failedRules
        }
        blockData {
          elementId
          elementName
          elementType
          alias
          order
          content
        }
      }
      collections {
        bestSeason
        copyType
        jobId
        displayName {
          line1
          line2
        }
        productCode
        jobAttributes {
          jobType
          rank
        }
        collectionId
        aiInfo {
          aiGenerated
          confidenceScore
        }
        copyBlocks {
          blockId
          blockName
          blockDesc
          order
          required
          position
          aiBlockInfo {
            aiRequestId
            aiGenerated
            isAccepted
            isEdited
            confidenceScore
            failedRules
          }
          blockData {
            elementId
            elementName
            elementType
            alias
            order
            content
          }
        }
      }
    }
  }
`;

export const GET_COPYJOB_CONFIGURATION = gql`
  query FetchCopyjobConfig($copyType: String!) {
    fetchConfiguration(copyType: $copyType) {
      status {
        statusName
        statusDesc
        reopen
        order
        editable
        workflow {
          direction
          flowType
          action {
            status
            desc
            convertToCopyType
          }
        }
      }
      copyBlocks {
        blockName
        blockDesc
        order
        minBlock
        maxBlock
        required
        blockData {
          elementName
          elementType
          alias
        }
        blockConfig {
          libraryEnabled
        }
      }
    }
  }
`;
export const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus(
    $jobId: String!
    $toStatus: String!
    $reopen: Boolean!
    $targetCopyType: String
  ) {
    updateJobStatus(
      jobId: $jobId
      toStatus: $toStatus
      reopen: $reopen
      targetCopyType: $targetCopyType
    ) {
      bestSeason
      jobId
      copyType
      copyTypeDesc
      displayName {
        line1
        line2
      }
      jobAttributes {
        jobType
        status
        reOpened
      }
      collectionId
      lastUpdatedDt
      lastUpdatedBy
      lastPublishedDt
      lastPublishedBy
      productCode
      aiInfo {
        aiGenerated
        confidenceScore
      }
      copyBlocks {
        blockId
        blockName
        blockDesc
        order
        required
        position
        aiBlockInfo {
          aiRequestId
          aiGenerated
          isAccepted
          isEdited
          confidenceScore
          failedRules
        }
        blockData {
          elementId
          elementName
          elementType
          alias
          order
          content
        }
      }
      collections {
        bestSeason
        copyType
        jobId
        displayName {
          line1
          line2
        }
        productCode
        jobAttributes {
          jobType
          rank
        }
        collectionId
        aiInfo {
          aiGenerated
          confidenceScore
        }
        copyBlocks {
          blockId
          blockName
          blockDesc
          order
          required
          position
          aiBlockInfo {
            aiRequestId
            aiGenerated
            isAccepted
            isEdited
            confidenceScore
            failedRules
          }
          blockData {
            elementId
            elementName
            elementType
            alias
            order
            content
          }
        }
      }
    }
  }
`;

export const SAVE_COPYAUTHORING_BLOCKS = gql`
  mutation SaveCopyAuthoringBlocks(
    $jobId: String!
    $blockRequest: [CopyBlockUpdateRequest!]!
  ) {
    updateBlock(jobId: $jobId, blockRequest: $blockRequest) {
      blockId
      blockName
      blockDesc
      order
      required
      blockData {
        elementId
        elementName
        elementType
        alias
        order
        content
      }
    }
  }
`;

export const REVIEW_COPY_JOB = gql`
  mutation reviewCopyJob(
    $copyType: String!
    $jobType: String!
    $productCodes: [String!]!
  ) {
    reviewJob(
      copyType: $copyType
      jobType: $jobType
      productCodes: $productCodes
    ) {
      validProductIds
      jobExistProductIds
      duplicateProductIds
      inValidProductIds
    }
  }
`;
export const CREATE_COPY_JOB = gql`
  mutation createCopyJob(
    $copyType: String!
    $jobType: String!
    $productCodes: [String!]!
  ) {
    createJob(
      copyType: $copyType
      jobType: $jobType
      productCodes: $productCodes
    ) {
      createdJobIds
    }
  }
`;

export const GET_DASHBOARD_CONFIG = gql`
  query FetchCopyjobDashboardConfig($dashboardType: String) {
    fetchDashboardConfiguration(dashboardType: $dashboardType) {
      domainName
      filterConfigurations {
        filterGroups {
          filters {
            name
            displayName
            filterOrder
            uiElementType
            multiSelect
            facets {
              name
              value
            }
          }
        }
      }
      tabConfigurations {
        tabs {
          name
          displayName
          tabOrder
        }
      }
      tableConfigurations {
        columns {
          name
          displayName
          columnOrder
          fieldName
        }
      }
    }
  }
`;

export const GET_DASHBOARD_CONFIGV3 = gql`
  query FetchDashboardConfigurationV3 {
    fetchDashboardConfigurationV3(
      domainName: "PRODUCT"
      dashboardType: "AUTHORING"
    ) {
      domainName
      dashboardType
      copyTabs {
        copyTab
        copyTypes
      }
      dashboardConfigurations {
        domainName
        dashboardType
        copyTab
        copyTypes
        filterConfigurations {
          filterGroups {
            filterGroupName
            isEnabled
            filters {
              name
              displayName
              groupName
              dataType
              filterOrder
              uiElementType
              isEnabled
              multiSelect
              facetKey
              facetType
              facets {
                name
                value
              }
            }
          }
        }
        tabConfigurations {
          tabs {
            name
            displayName
            tabOrder
            isEnable
            isDefault
          }
        }
        tableConfigurations {
          columns {
            name
            displayName
            columnOrder
            isEnable
            fieldName
            isSortable
            isHeaderHidden
            bodyConfig {
              fields
              itemType
              actions {
                name
                value
                uri
              }
            }
            actions {
              name
              value
              uri
            }
            styles {
              key
              value
            }
          }
        }
      }
      filterFacets {
        name
        values {
          name
          value
        }
      }
    }
  }
`;
export const GET_COPYJOBS = gql`
  query FetchCopyjobs($searchRequestCopySearch: SearchRequestCopySearch!) {
    fetchDetails(searchRequestCopySearch: $searchRequestCopySearch) {
      data {
        jobId
        jobName
        displayName {
          line1
          line2
        }
        copyType
        copyTypeDesc
        jobType
        status
        reOpened
        productCode
        vendor
        plm
        division
        season
        developmentTeamName
        updateDt
        aiGenerated
        isDisabled
        reviewStatus
        aiStatus
        reviewDesc
      }
      pages {
        currentPage
        totalPages
        totalItems
        pageSize
      }
    }
  }
`;

export const ADD_THREAD = gql`
  mutation AddCopyNoteThread(
    $request: CopyNoteRequest!
    $isReviewer: Boolean!
  ) {
    addThread(request: $request, isReviewer: $isReviewer) {
      jobId
      copyType
      threads {
        threadId
        createdDt
        createdBy
        isDeletable
        content
        tags
        target {
          targetId
          targetType
          targetDesc
          productIds
        }
        notes {
          noteId
          lastModifiedDt
          lastModifiedBy
          content
        }
      }
    }
  }
`;

export const GET_COPY_NOTES = gql`
  query FetchCopyNotes($jobId: String!, $isReviewer: Boolean!) {
    copyNotes(jobId: $jobId, isReviewer: $isReviewer) {
      jobId
      copyType
      filters {
        filterName
        filterValue
      }
      threads {
        threadId
        createdDt
        createdBy
        isDeletable
        content
        tags
        target {
          targetId
          targetType
          targetDesc
          productIds
        }
        notes {
          noteId
          lastModifiedDt
          lastModifiedBy
          content
        }
      }
    }
  }
`;

export const ADD_NOTE = gql`
  mutation AddCopyNote($request: CopyNoteRequest!, $isReviewer: Boolean!) {
    addNote(request: $request, isReviewer: $isReviewer) {
      jobId
      copyType
      threads {
        threadId
        createdDt
        createdBy
        isDeletable
        content
        tags
        target {
          targetId
          targetType
          targetDesc
          productIds
        }
        notes {
          noteId
          lastModifiedDt
          lastModifiedBy
          content
        }
      }
    }
  }
`;

export const DELETE_THREAD = gql`
  mutation DeleteCopyNoteThread(
    $jobId: String!
    $threadId: String!
    $isReviewer: Boolean!
  ) {
    deleteThread(jobId: $jobId, threadId: $threadId, isReviewer: $isReviewer) {
      jobId
      copyType
      filters {
        filterName
        filterValue
      }
      threads {
        threadId
        createdDt
        createdBy
        isDeletable
        content
        tags
        target {
          targetId
          targetType
          targetDesc
          productIds
        }
        notes {
          noteId
          lastModifiedDt
          lastModifiedBy
          content
        }
      }
    }
  }
`;

export const GET_TRANSLATION_LANGUAGES = gql`
  query fetchLanguages($localizationId: String!) {
    fetchLanguages(localizationId: $localizationId) {
      name
      desc
      status
    }
  }
`;

export const GET_COPY_TRANSLATIONS = gql`
  query fetchCopyTranslation($localizationId: String!) {
    fetchCopyTranslation(localizationId: $localizationId) {
      localizationId
      copyJobId
      isSource
      productCode
      language
      copyType
      createdDt
      updateDt
      styleCode
      workflowStatus
      displayName {
        line1
        line2
      }
      translationJobs {
        dueDate
        creationDate
        isRetrigger
        translationJobId
        retriggerReason
        locPlatformJobId
        status
        locPlatformResponseDate
        locPlatformRequestDate
        targetLanguages {
          name
          desc
          status
        }
      }
      translationJob {
        dueDate
        creationDate
        isRetrigger
        translationJobId
        retriggerReason
        locPlatformJobId
        status
        locPlatformRequestDate
        locPlatformResponseDate
      }
      copyBlocks {
        blockId
        blockName
        blockDesc
        required
        position
        blockData {
          elementId
          elementName
          elementType
          alias
          content
          isEdited
          isLocked
        }
      }
    }
  }
`;

export const GET_REVIEWERS = gql`
  query fetchCopyReviewers($jobId: String!) {
    reviewerThread(jobId: $jobId) {
      jobId
      dueDate
      status
      reviewers {
        reviewerId
        emailId
        status
        createdDt
        createdBy
        lastModifiedDt
      }
      threads {
        threadId
        createdDt
        lastModifiedDt
        createdBy
        content
        tags
        target {
          targetId
          targetType
          targetDesc
          productIds
        }
        notes {
          noteId
          lastModifiedDt
          lastModifiedBy
          content
          isDeletable
        }
        isResolved
        isDeletable
      }
      filters {
        filterName
        filterValue
      }
      copyJobReviewerInfo {
        reviewerId
        emailId
        status
        lastModifiedDt
      }
    }
  }
`;

export const ADD_REVIEWERS = gql`
  mutation addCopyReviewers($request: CopyReviewRequest!) {
    addReviewer(request: $request) {
      jobId
      dueDate
      reviewers {
        reviewerId
        emailId
        status
        createdDt
        createdBy
        lastModifiedDt
      }
    }
  }
`;

export const REMOVE_REVIEWER = gql`
  mutation ($jobId: String!, $reviewerId: String!) {
    removeReviewer(jobId: $jobId, reviewerId: $reviewerId) {
      jobId
      reviewers {
        reviewerId
        emailId
        status
        createdDt
        createdBy
        lastModifiedDt
      }
    }
  }
`;

export const UPDATE_DUE_DATE = gql`
  mutation ($request: CopyReviewRequest!) {
    updateReviewDueDate(request: $request) {
      jobId
      dueDate
      reviewers {
        reviewerId
        emailId
        status
        createdDt
        createdBy
        lastModifiedDt
      }
    }
  }
`;

export const UPDATE_COPY_BLOCKS = gql`
  mutation (
    $localizationId: String!
    $language: String!
    $copyBlocks: [CopyBlocksUpdateRequest]!
  ) {
    updateCopyBlocks(
      localizationId: $localizationId
      language: $language
      copyBlocks: $copyBlocks
    ) {
      localizationId
      copyJobId
      isSource
      productCode
      language
      copyType
      createdDt
      updateDt
      styleCode
      displayName {
        line1
        line2
      }
      translationJobs {
        dueDate
        creationDate
        isRetrigger
        translationJobId
        retriggerReason
        locPlatformJobId
        status
        locPlatformRequestDate
        locPlatformResponseDate
        targetLanguages {
          name
          desc
          status
        }
      }
      translationJob {
        dueDate
        creationDate
        isRetrigger
        translationJobId
        retriggerReason
        locPlatformJobId
        status
        locPlatformRequestDate
        locPlatformResponseDate
      }
      copyBlocks {
        blockId
        blockName
        blockDesc
        required
        blockData {
          elementId
          elementName
          elementType
          alias
          content
          isEdited
          isLocked
        }
      }
    }
  }
`;

export const FETCH_PCL_JOBS = gql`
  query fetchDashboardData($searchRequest: SearchRequest!) {
    fetchDashboardData(searchRequest: $searchRequest) {
      data {
        localizationId
        displayName {
          line1
          line2
        }
        productId
        translationDueDate
        sourceCopyReceivedAt
        mostRecentJobId
        languageCount
        workflowStatus
        language
      }
      pages {
        totalItems
        currentPage
        totalPages
        pageSize
      }
    }
  }
`;

export const RETRIGGER_TRANSLATIONS_NEW = gql`
  query RetriggerTranslations(
    $localizationIds: [String]!
    $targetLanguages: [String]!
    $reason: String!
    $forceTranslation: Boolean!
  ) {
    retriggerTranslations(
      retriggerTranslationsRequest: {
        localizationIds: $localizationIds
        targetLanguages: $targetLanguages
        reason: $reason
        forceTranslation: $forceTranslation
      }
    ) {
      processedProductCodeTargetLanguages {
        productCode
        targetLanguages
      }
      failedProductCodeTargetLanguages {
        productCode
        targetLanguages
      }
      ignoredProductCodeTargetLanguages {
        productCode
        targetLanguages
      }
      newlyCreatedProductCodeTargetLanguages {
        productCode
        targetLanguages
      }
    }
  }
`;

export const GET_ENABLED_TARGET_LANGUAGES = gql`
  query GetEnabledLanguage($productCodes: [String!]) {
    getEnabledLanguage(productCodes: $productCodes) {
      id
      name
    }
  }
`;

export const GET_TRANSLATED_DATA_OF_SOURCE = gql`
  query getTranslatedDataOfSource($localizationId: String!) {
    getTranslatedDataOfSource(localizationId: $localizationId) {
      localizationId
      displayName {
        line1
        line2
      }
      productId
      workflowStatus
      translationDueDate
      mostRecentJobId
      language {
        name
        desc
        status
      }
    }
  }
`;

export const UPDATE_REVIEW_STATUS = gql`
  mutation UpdateReviewStatus(
    $reviewerId: String!
    $request: CopyReviewRequest!
  ) {
    updateReviewStatus(reviewerId: $reviewerId, request: $request) {
      jobId
      dueDate
      status
      copyJobReviewerInfo {
        reviewerId
        emailId
        status
        lastModifiedDt
      }
    }
  }
`;

export const ADD_BULK_REVIEWERS = gql`
  mutation ($request: BulkReviewRequest!) {
    bulkReviewRequest(request: $request)
  }
`;

export const FETCH_DASHBOARD_CHILD_COPIES = gql`
  query fetchDashboardChildCopies($collectionId: String!) {
    fetchChildCopiesDashboardResponse(collectionId: $collectionId) {
      data {
        jobId
        jobName
        copyType
        copyTypeDesc
        jobType
        status
        reOpened
        productCode
        vendor
        season
        division
        plm
        developmentTeamName
        collectionId
        collectionType
        displayName {
          line1
          line2
        }
        updateDt
      }
      pages {
        totalItems
        currentPage
        totalPages
        pageSize
      }
    }
  }
`;

export const ADD_PRODUCT_TO_COLLECTION = gql`
  mutation AddProductToCollection(
    $copyCollectionRequest: CopyCollectionRequest!
  ) {
    addProductToCollection(copyCollectionRequest: $copyCollectionRequest) {
      jobId
    }
  }
`;

export const REMOVE_PRODUCT_FROM_COLLECTION = gql`
  mutation RemoveProductFromCollection(
    $copyCollectionRequest: CopyCollectionRequest!
  ) {
    removeProductFromCollection(copyCollectionRequest: $copyCollectionRequest) {
      jobId
    }
  }
`;

export const REARRANGE_COLLECTION_PRODUCTS = gql`
  mutation ReArrangeProductInCollection(
    $copyCollectionRequest: CopyCollectionRequest!
  ) {
    reArrangeProductInCollection(
      copyCollectionRequest: $copyCollectionRequest
    ) {
      jobId
      collections {
        jobId
      }
    }
  }
`;

export const CREATE_BLOCK = gql`
  mutation createBlock(
    $jobId: String!
    $blockRequest: [CopyBlockCreateRequest!]!
  ) {
    createBlock(jobId: $jobId, blockRequest: $blockRequest) {
      blockId
      blockName
      blockDesc
      order
      required
      position
      blockData {
        elementId
        elementName
        elementType
        alias
        order
        content
      }
    }
  }
`;
export const DELETE_BLOCK = gql`
  mutation deleteBlock(
    $jobId: String!
    $blockRequest: [CopyBlockDeleteRequest!]!
  ) {
    deleteBlock(jobId: $jobId, blockRequest: $blockRequest) {
      blockId
      blockName
      blockDesc
      order
      required
      position
      blockData {
        elementId
        elementName
        elementType
        alias
        order
        content
      }
    }
  }
`;
export const FETCH_DOMAIN = gql`
  query FetchDomain {
    fetchDomain {
      copyTypes {
        copyTypeName
        copyTypeDesc
        metaDataConfig {
          creatableBy
        }
      }
    }
  }
`;
